/* eslint-disable */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-undef */
import React, { useEffect, useState } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, 
  Card, CardContent, Grid, Box, Popover, TextField, Chip, Autocomplete, 
  CircularProgress, Alert, Button
} from '@mui/material';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DOMPurify from 'dompurify';
import { uniqBy } from 'lodash';
import { useSelector } from 'react-redux';
import { CommHandler } from '../../../utils';
import { getCurrentYear } from '../../../utils/utils';
import { config } from '../../../config';
import { Loader } from '../../../components/Loader';
import { ViewGrid, StyledLoaderView } from './viewArtifact.styled';
import ImagePreview from './ImagePreview';
import { cardContentStyle } from '../Artifact.styled';
import { ToastProps } from '../../../components/Toast/types';
import Toast from '../../../components/Toast/Toast';
import { getAuthHeader, getCurrentQuarter, mapSeverityToAlertColor } from '../../../utils/utils';
import { MessageAlert } from '../../../components/MessageAlert';
import Chat from './chat/Chat';
import { showSuccessSnackbar, showErrorSnackbar } from '../../../components/Snackbar/Snackbar';

const ViewArtifactDetails = () => {
  const loggedUser = useSelector((state: any) => state.user.employee);
  const currentYear = (useSelector((state: any) => state.appraisalCycle.selectedYear) || getCurrentYear())?.toString();
  const { employee = {} } = useSelector((state: any) => state.user) || {};
  const { artifactFormId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [categoryName, setCategoryName] = useState<any>({ name: '', code: '' });
  const [subCategoryName, setSubCategoryName] = useState();
  const [fileUrl, setfileUrl] = useState('');
  const [fileType, setfileType] = useState('');
  const [showBuArtifact, setshowBuArtifact] = useState(false);
  const [artifactFormDetails, setArtifactFormDetails] = useState<any>({});
  const [alertType, setAlertType] = useState<'success' | 'info' | 'warning'>('success');
  const [alertHeader, setAlertHeader] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchBoxValue, setSearchBoxValue] = useState<any[]>([]);
  const [approverList, setApprover] = useState<any[]>([]);
  const [addReviewers, SetAddReviewers] = useState<any[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const listUser = useSelector((state: any) => state.employees.list);
  const [fixedOptions, setFixedOption] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [actionData, setActionData] = useState(null);
  const [rejectPending, setRejectPending] = useState(false);
  const [toastmsg, setToastMsg] = useState<ToastProps>({
    open: false,
    autoHideDuration: 9000,
    message: '',
    vertical: 'top',
    horizontal: 'center',
    severity: '',
  });
  const [canChat, setCanChat] = useState(false);

  const artifactActionStyles = {
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px',
    gap: '10px',
  };
  
  const [showChat, setShowChat] = useState(location.state?.showChat || false);

  const handleURLSelect = async (item: any) => {
    try {
      const response = await CommHandler.get(`${config.apiBaseUrl}/uploadFiles/get-signed-url`, {
        params: { key: item },headers: getAuthHeader,
      });
      const signedUrl = response;
      if (signedUrl) {
        const str = `${config.s3URL}${config.permanentBucket}/${item}`;
        const result = str?.substring(str.length - 3);
        setfileUrl(str);
        setfileType(result);
        setshowBuArtifact(true);
      } else {
        // eslint-disable-next-line
        console.error('Signed URL is undefined');
      }
    } catch (error) {
      // eslint-disable-next-line
      console.error('Error handling file select:', error.message);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const [subCategoriesResponse, categoryListResponse, filtredData] = await Promise.all([
          CommHandler.request({
            url: `${config.adminApiURL}/artifact/subcategory`,
            method: 'GET',
            params: { status: 1 },
            headers: getAuthHeader,
          }),
          CommHandler.request({
            url: `${config.adminApiURL}/artifact/category`,
            method: 'GET',
            params: { status: 1 },
            headers: getAuthHeader,
          }),
          CommHandler.request({
            url: `${config.apiBaseUrl}/artifact/getArtifact`,
            method: 'POST',
            data: { id: artifactFormId },
            headers: getAuthHeader,
          }),
        ]);

        const {
          __v: v, id: d, createdAt, ...filteredData
        } = filtredData ? filtredData.data : [];
        setArtifactFormDetails(filteredData);
        let approverListToProcess=[];
        if(!Array.isArray(filteredData?.approverList) && (typeof filteredData?.approverList == 'object')){
          const {primaryApprover = [], secondaryApprover = []} = filteredData?.approverList;
          let filtered=[...primaryApprover,...secondaryApprover];
          approverListToProcess = filtered.filter((item, index, self) => 
            index === self.findIndex((inItem) =>inItem.id === item.id)
          );
        }else {
          approverListToProcess=filtredData.data.approverList;
        }
        const loggedInUserId = localStorage.getItem('user_id');
        if(loggedInUserId){
          setCanChat(approverListToProcess.some((approver) => parseInt(approver.id) === parseInt(loggedInUserId)) || parseInt(filtredData.data.user_id) === parseInt(loggedInUserId));
        }
        setApprover(approverListToProcess);
        const catName: any = categoryListResponse?.data?.filter((cat: any) => cat.id === filteredData?.artifact_category_id);
        setCategoryName({ name: catName[0]?.name, code: catName[0]?.code });

        const subCategoryname: any = subCategoriesResponse.data?.filter((subCat: any) => subCat.id === filteredData?.artifact_sub_category_id);
        setSubCategoryName(subCategoryname[0]?.name);
        setLoading(false);
        return [subCategoriesResponse, categoryListResponse];
      } catch (err) {
        setLoading(false);
        setToastMsg({
          open: true,
          message: 'Something went wrong',
          vertical: 'top',
          horizontal: 'center',
          severity: 'error',
        });
        return false;
      }
    }
    fetchData();
  }, [artifactFormDetails?.artifact_category_id, artifactFormDetails?.artifact_sub_category_id, artifactFormId]);
  const boldTypography = (label: any, value: any) => (
    <>
      <strong>
        {label}
        {' '}
        :
        {' '}
      </strong>
      {' '}
      <span>{value}</span>
    </>
  );

  useEffect(() => {
    if (artifactFormDetails.artifact_sub_category_id === 92) {
      const currentQuarter = `Quarter ${getCurrentQuarter()}`;
      setArtifactFormDetails((prevDetails) => ({ ...prevDetails, quarter: currentQuarter }));
    }
  }, [artifactFormDetails.artifact_sub_category_id]);

  const handleCloseAlert = () => {
    if (alertType === 'success' || alertType === 'warning') {
      setShowAlertMessage(false);
    }
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleShowAlert = (header: string, message: string, type: 'success' | 'info' | 'warning') => {
    setAlertType(type);
    setAlertHeader(header);
    setAlertMessage(message);
    setShowAlertMessage(true);
  };
  const handleUpdateReviewer = async () => {
    try {
      setIsLoading(true);
      const response = await CommHandler.request({
        url: `${config.apiBaseUrl}/artifact/updateApprover`,
        method: 'PUT',
        headers: getAuthHeader,
        data: { id: artifactFormDetails?.id, data: addReviewers },
      });
      if (response?.status === 200) {
        setApprover(response?.data.approverList);
        setIsLoading(false);
        handleClosePopover();
        handleShowAlert('Update Reviewer', 'Reviewer update successfully!', 'success');
      } else {
        handleShowAlert('Update Reviewer', 'Some error occurred while updating artifact Reviewer!', 'warning');
      }
    } catch (error) {
      // eslint-disable-next-line
      console.error('error', error);
    }
  };

  const handClickBack = () => {
    navigate(-1);
  };
  const onDismiss = () => {
    setToastMsg({
      open: false,
      message: '',
      horizontal: 'center',
      vertical: 'top',
      severity: '',
    });
  };
  const handleAddReviewers = (reviewers: any[]) => {
    const finalData = uniqBy(reviewers, 'id');
    setSearchBoxValue(finalData);
    SetAddReviewers(finalData);
  };
  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>, formatData: any) => {
    const extractedData = formatData?.map((updatedData) => ({
      id: updatedData?.id,
      name: updatedData?.name,
    }));
    const addOption = extractedData?.filter((filterRe) => filterRe?.id === artifactFormDetails?.loggedInUserId);
    setFixedOption(addOption);
    setSearchBoxValue(extractedData);
    SetAddReviewers(extractedData);
    setAnchorEl(event.currentTarget);
  };

  const extractedReviewers = listUser?.map((listData) => ({
    id: listData?.user_id,
    name: listData?.fullName,
  }));

  const isUserApprover = (user_id, approverList) => {
    const UserId = employee?.id?.toString();
    if (Array.isArray(approverList)) {
      return approverList.some((approver) => approver?.id == UserId);
    } else if (approverList && typeof approverList === 'object') {
      const allApprovers = [
        ...(approverList.primaryApprover || []),
        ...(approverList.secondaryApprover || [])
      ];
      return allApprovers.some((approver) => approver?.id == UserId);
    }
    return false;
  };

  const updateStatus = async (actionLabel: string) => {
    const UserId = getAuthHeader['x-user_id'] || employee?.id?.toString();
    const approvedstatus = actionLabel;
    try {
        const response = await CommHandler.request({
          url: `${config.apiBaseUrl}/artifact/updateStatus`,
          method: 'PUT',
          headers: getAuthHeader,
          params: {
            year: currentYear,
          },
          data: { id: [artifactFormId], status: approvedstatus, user_id: UserId },
        });
        if (response.status === 200) {
          showSuccessSnackbar(`Artifact ${approvedstatus} successfully!`);
          navigate(-1);
        } else {
          showErrorSnackbar('Something went wrong while updating the artifact status.');
        }
      } catch (error) {
        throw error;
      }
  };

  const handleCancelConfirmation = () => setShowConfirmationDialog(false);

  const handleConfirmCancelConfirmation = () => {
    if (actionData?.status) {
      updateStatus(actionData.status);
    }
    setShowConfirmationDialog(false);
  };

  const handleActionClick = (status, actionLabel) => {
    setActionData({ status, actionLabel });
    setShowConfirmationDialog(true);
    setRejectPending(false);
  };

  useEffect(() => {
    if (location.state?.autoReject) {
      handleRejectButtonClick();
    }
  }, [location.state]);

  const handleRejectButtonClick = () => {
    setShowChat(true);
    setRejectPending(true);
  };

  return (
    <>
      <Dialog className="btn_style_prt" open={showConfirmationDialog}>
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to
              {' '}
              {actionData?.actionLabel.toLowerCase()}
              {' '}
              artifact?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelConfirmation}>No</Button>
            <Button onClick={handleConfirmCancelConfirmation}>Yes</Button>
          </DialogActions>
        </Dialog>
      {/* {showChat && <Chat artifactFormDetails={artifactFormDetails} />} */}
      {toastmsg && (
        <Toast open={toastmsg.open} autoHideDuration={toastmsg.autoHideDuration} message={toastmsg.message} vertical={toastmsg.vertical} horizontal={toastmsg.horizontal} onClose={onDismiss}>
          <Alert onClose={onDismiss} severity={mapSeverityToAlertColor(toastmsg.severity)} sx={{ width: '100%' }}>
            {toastmsg.message}
            !
          </Alert>
        </Toast>
      )}
      {!loading ? (
        <Card variant="outlined">
          <CardContent style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <div onClick={handClickBack} onKeyPress={(e: { key: string }) => e.key === 'Enter' && handClickBack()} role="button" tabIndex={0}>
              <img src="/leftArrow.png" alt="left-arrow" width="16px" height="16px" style={{ marginRight: '12px', marginTop: '5px' }} />
            </div>
            {' '}
            <h2 style={cardContentStyle}>View Artifact</h2>
            <MessageAlert open={showAlertMessage} onClose={handleCloseAlert} header={alertHeader || 'Alert'} message={alertMessage || 'An error occurred.'} alertType={alertType} />
            <Popover
              style={{ marginRight: '97px' }}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <Box p={5}>
                <Autocomplete
                  multiple
                  id="add-reviewer-autocomplete"
                  options={extractedReviewers}
                  value={searchBoxValue}
                  getOptionLabel={(option: any) => option.name}
                  onChange={(_, value) => {
                    if (value.length === 0) {
                      const newValue = searchBoxValue.filter((option) => fixedOptions.indexOf(option) !== -1);
                      handleAddReviewers(newValue);
                    } else {
                      handleAddReviewers(value);
                    }
                  }}
                  renderTags={(value, getTagProps) => value.map((option, index) => <Chip label={option.name} {...getTagProps({ index })} disabled={fixedOptions.indexOf(option) !== -1} />)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Reviewers"
                      variant="outlined"
                      onKeyDown={(event: any) => {
                        if (event.key === 'Backspace') {
                          event.stopPropagation();
                        }
                      }}
                    />
                  )}
                />
                <Box mt={2} display="flex" justifyContent="space-between">
                  <Button onClick={handleClosePopover} color="primary">
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => {
                      handleUpdateReviewer();
                    }}
                  >
                    {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Add'}
                  </Button>
                </Box>
              </Box>
            </Popover>
            <div
              style={{
                marginLeft: 'auto',
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              {
                isUserApprover(artifactFormDetails?.user_id, artifactFormDetails?.approverList) &&
                (artifactFormDetails?.approved === 'pending' || artifactFormDetails?.approved === 'partiallyApproved') && (
                  <>
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{
                        borderRadius: '8px',
                        borderColor: 'green',
                        color: 'green',
                        marginRight: '10px',
                        '&:hover': {
                          backgroundColor: 'rgba(0, 128, 0, 0.1)',
                          borderColor: 'green',
                        },
                      }}
                      onClick={() => handleActionClick('approved', 'Approve')}
                    >
                      Approve
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{
                        borderRadius: '8px',
                        borderColor: 'red',
                        color: 'red',
                        marginRight: '10px',
                        '&:hover': {
                          backgroundColor: 'rgba(255, 0, 0, 0.1)',
                          borderColor: 'red',
                        },
                      }}
                      onClick={() => {
                        setShowChat(true);
                        setRejectPending(true);
                      }}
                    >
                      Reject
                    </Button>
                  </>
                )
              }
              {canChat && <Button variant="outlined" sx={{ borderRadius: '10px' }} onClick={() => setShowChat(true)}>
                Add Comments
              </Button>}
              {loggedUser?.isSuperAdmin && artifactFormDetails?.approved !== 'approved' && artifactFormDetails?.approved !== 'rejected' && (
                <Button className="addArtifact-btn" variant="outlined" sx={{ borderRadius: '10px', marginLeft: '10px', marginRight: '16px' }} onClick={(e) => handleOpenPopover(e, approverList)}>
                  Add Reviewer
                </Button>
              )}
            </div>
          </CardContent>
          <CardContent>
            <DialogContent>
              <Grid container spacing={2}>
                <ViewGrid item xs={12} className="view-artifact-detail-field">
                  {boldTypography('Artifact Name', artifactFormDetails?.name)}
                </ViewGrid>
                <ViewGrid item xs={12} className="view-artifact-detail-field">
                  {boldTypography('Artifact Subcategory', subCategoryName)}
                </ViewGrid>
                {artifactFormDetails?.artifact_sub_category_id === 92 ? (
                  <ViewGrid item xs={12} className="view-artifact-detail-field">
                  {boldTypography('Quarter', artifactFormDetails.quarter)}
                </ViewGrid>
                ): null}
                {artifactFormDetails?.selectedlineItem ? (
                  <ViewGrid item xs={12} className="view-artifact-detail-field">
                    {boldTypography('Line Item', artifactFormDetails.selectedlineItem)}
                  </ViewGrid>
                ) : null}
                {categoryName?.code === 'SQU' && artifactFormDetails?.project_name && (
                  <>
                    <ViewGrid item xs={12} className="view-artifact-detail-field">
                      {boldTypography('Project Name', artifactFormDetails?.project_name)}
                    </ViewGrid>
                  </>
                )}
                {categoryName?.code === 'SQU' && (
                  <>
                    <ViewGrid item xs={12} className="view-artifact-detail-field">
                      <strong>URL:</strong>
                      {' '}
                      <a href={artifactFormDetails?.url} target="_blank" rel="noopener noreferrer">
                        {artifactFormDetails?.url}
                      </a>
                    </ViewGrid>
                  </>
                )}
                <ViewGrid item xs={12} className="view-artifact-detail-field">
                  {boldTypography('Points', artifactFormDetails?.achieved_points)}
                </ViewGrid>
                <ViewGrid item xs={12}>
                  {(() => {
                    if (artifactFormDetails?.approved === 'approved') {
                      return boldTypography('Approved By', artifactFormDetails?.approved_by);
                    }
                    if (artifactFormDetails?.approved === 'rejected') {
                      return boldTypography('Rejected By', artifactFormDetails?.approved_by);
                    }
                    return boldTypography('Approved By', artifactFormDetails?.approved_by);
                  })()}
                </ViewGrid>
                <ViewGrid item xs={12} style={{ display: 'flex' }}>
                  {boldTypography('Approver List', approverList?.map((approver: any) => approver.name).join(', '))}
                </ViewGrid>
                {categoryName?.code === 'PRO' && (
                  <>
                    <ViewGrid item xs={12} className="view-artifact-detail-field">
                      {boldTypography('Project Name', artifactFormDetails?.project_name)}
                    </ViewGrid>
                    <ViewGrid item xs={12} className="view-artifact-detail-field">
                      {boldTypography('Project Manager', artifactFormDetails?.project_manager?.map((id: any) => id.name).join(', '))}
                    </ViewGrid>
                    <ViewGrid item xs={12}>
                      {boldTypography('Currently Working on this Project', artifactFormDetails?.project_working_status ? ' Yes' : ' No')}
                    </ViewGrid>
                    <ViewGrid item xs={12} className="view-artifact-detail-field">
                      {boldTypography('Start Date', artifactFormDetails?.from_date)}
                    </ViewGrid>
                    {artifactFormDetails?.project_working_status === false && (
                      <ViewGrid item xs={12} className="view-artifact-detail-field">
                        {boldTypography('End Date', artifactFormDetails?.to_date)}
                      </ViewGrid>
                    )}
                  </>
                )}
                {categoryName?.code === 'FB' && (
                  <>
                    <ViewGrid item xs={12} className="view-artifact-detail-field">
                      {boldTypography('Quarter', artifactFormDetails?.quarter)}
                    </ViewGrid>
                    <ViewGrid item xs={12} className="view-artifact-detail-field">
                      {boldTypography('Rating', artifactFormDetails?.ratings)}
                    </ViewGrid>
                    <ViewGrid item xs={12} className="view-artifact-detail-field">
                      {boldTypography('Area of improvement', artifactFormDetails?.area_of_improvement)}
                    </ViewGrid>
                  </>
                )}
                <ViewGrid item xs={12}>
                  <div className="view-artifact-detail-field">
                    <strong>Description :</strong>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(artifactFormDetails?.description),
                      }}
                      style={{
                        display: 'inline-block',
                        verticalAlign: 'top',
                        marginTop: categoryName?.code === 'FB' ? '1px' : '-14px',
                      }}
                    />
                  </div>
                </ViewGrid>
                <Grid item xs={12}>
                  <div
                    className="artifact-action"
                    style={{
                      ...artifactActionStyles,
                      display: 'flex',
                      gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
                      gap: '10px',
                      overflowX: 'auto',
                      flexDirection: 'row',
                    }}
                  >
                    <strong style={{ width: '155px' }}>Attachments:</strong>
                    {' '}
                    {artifactFormDetails?.file_path?.map((file: any) => {
                      const fileTyp = file.split('.');
                      return (
                        <div
                          className="viewArtifactBtns"
                          key={file}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            padding: '10px 0',
                          }}
                        >
                          <div key={file}>
                            <div className="preview-image-add">
                              {fileTyp.includes('pdf') ? (
                                <PictureAsPdfIcon
                                  style={{
                                    height: '60px',
                                    width: '60px',
                                    color: '#5788d7',
                                  }}
                                />
                              ) : (
                                <img
                                  src={`${config.s3URL}${config.permanentBucket}/${file}`}
                                  alt="Attachment"
                                  style={{
                                    width: 'auto',
                                    height: '60px',
                                  }}
                                />
                              )}
                              <VisibilityOutlinedIcon className="view-icon" sx={{ color: '#ffffff', cursor: 'pointer' }} onClick={() => handleURLSelect(file)} />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Grid>
              </Grid>
            </DialogContent>
            {showChat && <Chat artifactFormDetails={artifactFormDetails} onClose={() => setShowChat(false)} showChat={showChat} rejectPending={rejectPending} handleActionClick={() => handleActionClick('rejected', 'Reject')}/>}
          </CardContent>
        </Card>
      ) : (
        <StyledLoaderView>
          <Loader />
        </StyledLoaderView>
      )}
      <Dialog
        open={showBuArtifact}
        onClose={() => setshowBuArtifact(false)}
        PaperProps={{
          style: { width: '80%', maxWidth: 'none' },
        }}
      >
        <ImagePreview type={fileType} url={fileUrl} setshowBuArtifact={setshowBuArtifact} />
      </Dialog>
    </>
  );
};
export default ViewArtifactDetails;