/* eslint-disable */
import { io, Socket } from 'socket.io-client';
import { config } from '../config';

const SOCKET_URL = `${config?.socketUrl}`;

const socket: Socket = io(SOCKET_URL)

socket.on('connect', () => {
  console.log('Connected to server');
});

socket.on('sendMessage', (data) => {
  console.log('Received message:', data);
});

socket.on('disconnect', (reason) => {
  console.log('Disconnected from server:', reason);
});

socket.on('connect_error', (error) => {
  console.error('Connection error:', error);
});

socket.on('reconnect_attempt', () => {
  console.log('Attempting to reconnect...');
});

export default socket;
