import { SvgIcon } from '@mui/material';
import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import FormControlLabel from '@mui/material/FormControlLabel';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

const ArtifactWrapper = styled.div`
.lable-select{
    font-size:18px !important;
    font-weight:bold;
}
.dropdown-button-icon-open {
    background-color: #fff;
    color: #2559C3;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 16px, 24px, 16px, 24px;
    text-transform: none;
    &:hover {
        background-color: #fff;
    }
}
.artifact-heading {
    color: #06152B;
    font-family: Lato;
    font-size: 32px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0px;
    margin-top: 24px;
}
    padding: 0px 10px;
    .artifact-btn-space {
        display: flex;
        justify-content: space-between;
        align-items: end;
        margin-top: 20px;
        
        .filter-btn {
            margin-right: 20px;
            font-size: 16px;
            font-weight: 500;
            font-style: Inter;
            line-height: 19.36px;
            color: #2559C3;
            border: 1px solid #2559C3;
            padding: 16px 24px;
            .filter-down-icon{
                margin-bottom:-15px;
            }
        }
        .addArtifact-btn {
            font-size: 16px;
            font-weight: 500;
            font-style: Inter;
            line-height: 19.36px;
            color: #ffffff;
            padding: 10px 22px;
            background: #2559C3;
        }
    }
    .employee-nav {
        .live-rank-tab-container {
            font-family:Inter;
            text-transform: capitalize;
            font-style: Inter;
            font-weight: 400;
            font-size: 14px;
            line-height: 16.94px;
            color: #848282;
        }
        .Mui-selected {
            color: #2559C3 !important;
        }
    }
    .artifact-main-table {
        border: 1px solid #D3D3D3;
        background-color: #ffffff;
        border-radius: 12px;
        padding: 0px 0px 13px 0px;
        margin: 12px 0;
        .certificate-heading {
            padding: 10px;
            font-family: Lato;
        }
        .override-tabpanel{
            padding: unset;
        }
        .table-row-item-cell {
            width: 100%;
            display: flex;
            align-items: center;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
            background: rgba(37, 89, 195, 10%);
            padding: 7px 32px;
            justify-content: space-between;
            height: 48px;
            margin: 16px 0;
        }
        .table-row-item {
            display: flex;
            align-items: center;
            border-radius: 10px;
            border: 1px solid #D3D3D3;
            background: #FFF;
            margin:12px;
            height: 63px;
            padding: 24px 19px;
            justify-content: space-between;
            .list-artifact {
                padding-left: 0px;
                .status-pending {
                    border-radius: 5px;
                    padding: 0px 10px;
                    gap: 10px;
                    background-color: #FFEED8;
                    font-style: Lato;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 27px;
                    color: #EDA13B;
                    word-break: break-word;
                }
                .status-draft {
                    border-radius: 5px;
                    padding: 0px 10px;
                    gap: 10px;
                    background-color: #D3D3D3;
                    font-family: Lato;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 27px;
                    color: :#fff; 
                }                  
                .status-approved {
                    border-radius: 5px;
                    padding: 0px 10px;
                    gap: 10px;
                    background-color: #0EAA00;
                    font-style: Lato;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 27px;
                    color: #DBFFD8
                }
                .status-rejected {
                    border-radius: 5px;
                    padding: 0px 10px;
                    gap: 10px;
                    background-color: #FF3333;
                    font-style: Lato;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 27px;
                    color: #DBFFD8
                }
                .partially-approved {
                    border-radius: 5px;
                    padding: 0px 10px;
                    gap: 10px;
                    background-color: #FFCCCC;
                    font-family: Lato;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 20px;
                    color: #FF0000;
                    text-align:center;
                }
                .listIem-text {
                    font-weight: 900;
                    font-size: 12px;
                    font-style: Inter;
                    padding:0px;
                    width: 100px;
                    text-overflow: ellipsis;
                    span{
                        font-family: Inter;
                        font-size: 12px;
                        font-weight: 500;
                        color:#000000;
                    }
                }
            }
        }
    }
    .artifact-table-head{
        font-family: Lato;
        font-size: 12px;
        font-weight: 500;
        color: #06152B;
        width: 145px;
    }
    .artifact-table-head-artifact{
        font-family: Lato;
        font-size: 12px;
        font-weight: 500;
        color: #06152B;
        width: 185px;
    }
    .list-artifact-box-wrap{
        width: 19%;
    }
    .table-row-profile-container{
        width: 8%;
    }

    .artifact-button-wrap{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;

const StyledArtifactIcon = styled(SvgIcon)`
  &.artifact-icon {
    margin-left: 10px;
    padding-right: 0px;
  }
  &.plus-icon {
    margin-left: 10px;
    padding: 0px;
  }
`;
const BootstrapDialog = styled(Dialog)`
  .css-tlc64q-MuiPaper-root-MuiDialog-paper {
    padding: 0;
  }
  & .MuiDialogContent-root {
    padding: 0;
  }
  & .MuiDialogActions-root {
    padding: 0;
  }
  .dialog-content {
    background-color: #f4f7fc;
    width: 100%;
    overflow-y: unset !important;
  }
  .MuiDialogContent-root {
    padding: 0; // No units needed for 0
  }
  .dialog-content-product {
    background-color: white;
    width: 100%;
  }
  .button-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 56%;
    margin: 20px auto 0px;
  }
  .button-image {
    width: 64px;
    height: 64px;
    margin-bottom: 10px;
  }
  .button-text {
    font-family: Lato;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
  }
  .css-tlc64q-MuiPaper-root-MuiDialog-paper {
    max-width: 800px !important;
  }

  .css-mbdu2s {
    max-width: 800px !important;
  }

  .artifact-popup-title {
    font-family: Lato;
    font-size: 24px;
    margin-left: 29px;
    margin-top: 30px;
    font-weight: 700;
    display: inline-block;
  }
`;
const buttonStyles = {
  width: '140px',
  height: '140px',
  borderRadius: '10px',
  border: '1px solid #E8E8E8',
  marginLeft: '13px',
  marginBottom: '13px',
  backgroundColor: 'white',
  color: 'black',
};

const cardContentStyle = {
  display: 'block',
  fontSize: '1.5em',
  marginBlockStart: '0.83em',
  marginBlockEnd: '0.83em',
  marginInlineStart: '0px',
  marginInlineEnd: '0px',
  fontWeight: 'bold',
};

const StyledFormControlLabel = styled(FormControlLabel)`
  color: #848282;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 0px !important;
  &.label-background {
    background: #edf3ff;
    box-shadow: 0px 4px 4px 0px rgba(37, 89, 195, 0.2);
  }
`;
const StyledMenuItem = styled(MenuItem)`
  padding: 0px !important;
`;
const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    width: 250px;
    height: auto;
    ul {
      div {
        padding: 10px;
        li {
          width: 100%;
          &:nth-child(odd) {
            background-color: #edf3ff;
          }
          &:nth-child(even) {
            background-color: #ffffff;
          }

          div {
            width: 100%;
            label {
              padding: 16px 12px;
              display: flex;
              justify-content: space-between;
              span {
                width: 90px;
                white-space: normal;
                overflow-wrap: break-word;
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
`;

const StyledDropdownWrapper = styled.div`
  margin-right: 25px;
  .dropdown-button-icon {
    border: 1px solid rgb(37, 89, 195) !important;
    background-color: rgb(255, 255, 255) !important;
    color: rgb(37, 89, 195) !important;

    &:hover {
      background-color: #fff;
    }
  }
  .dropdown-button-icon-open {
    border: 1px solid rgb(37, 89, 195) !important;
    background-color: rgb(255, 255, 255) !important;
    color: rgb(37, 89, 195) !important;

    &:hover {
      background-color: #fff;
    }
  }
`;
const StyledLiveRankIcon = styled(SvgIcon)`
  &.live-icon {
    margin-top: 10px;

    path {
      fill: #2559c3;
    }
  }
`;
const StyledLiveRankButton = styled(Button)`
  left: 16px;
  .MuiButton-endIcon {
    margin-left: 30px;
  }
  .dropdown-button-icon {
    background-color: #fff;
    color: #848282;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: none;
    padding: 16px, 24px, 16px, 24px;
    &:hover {
      background-color: #fff;
    }
  }
  .dropdown-button-icon-open {
    background-color: #fff;
    color: #2559c3;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 16px, 24px, 16px, 24px;
    text-transform: none;
    &:hover {
      background-color: #fff;
    }
  }
`;
export {
  ArtifactWrapper, StyledArtifactIcon, BootstrapDialog, buttonStyles, StyledLiveRankButton, StyledDropdownWrapper, StyledLiveRankIcon, StyledMenu, StyledMenuItem, StyledFormControlLabel, cardContentStyle,
};
